exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-accommodation-jsx-content-file-path-src-data-accommodations-prakka-base-camp-en-mdx": () => import("./../../../src/templates/accommodation.jsx?__contentFilePath=/home/runner/work/summit-property/summit-property/src/data/accommodations/prakka-base-camp/en.mdx" /* webpackChunkName: "component---src-templates-accommodation-jsx-content-file-path-src-data-accommodations-prakka-base-camp-en-mdx" */),
  "component---src-templates-accommodation-jsx-content-file-path-src-data-accommodations-prakka-base-camp-fr-mdx": () => import("./../../../src/templates/accommodation.jsx?__contentFilePath=/home/runner/work/summit-property/summit-property/src/data/accommodations/prakka-base-camp/fr.mdx" /* webpackChunkName: "component---src-templates-accommodation-jsx-content-file-path-src-data-accommodations-prakka-base-camp-fr-mdx" */),
  "component---src-templates-accommodation-jsx-content-file-path-src-data-accommodations-prakka-le-chalet-en-mdx": () => import("./../../../src/templates/accommodation.jsx?__contentFilePath=/home/runner/work/summit-property/summit-property/src/data/accommodations/prakka-le-chalet/en.mdx" /* webpackChunkName: "component---src-templates-accommodation-jsx-content-file-path-src-data-accommodations-prakka-le-chalet-en-mdx" */),
  "component---src-templates-accommodation-jsx-content-file-path-src-data-accommodations-prakka-le-chalet-fr-mdx": () => import("./../../../src/templates/accommodation.jsx?__contentFilePath=/home/runner/work/summit-property/summit-property/src/data/accommodations/prakka-le-chalet/fr.mdx" /* webpackChunkName: "component---src-templates-accommodation-jsx-content-file-path-src-data-accommodations-prakka-le-chalet-fr-mdx" */),
  "component---src-templates-accommodations-jsx": () => import("./../../../src/templates/accommodations.jsx" /* webpackChunkName: "component---src-templates-accommodations-jsx" */),
  "component---src-templates-booking-jsx": () => import("./../../../src/templates/booking.jsx" /* webpackChunkName: "component---src-templates-booking-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-general-rental-conditions-jsx-content-file-path-src-data-general-rental-conditions-en-mdx": () => import("./../../../src/templates/general-rental-conditions.jsx?__contentFilePath=/home/runner/work/summit-property/summit-property/src/data/general-rental-conditions/en.mdx" /* webpackChunkName: "component---src-templates-general-rental-conditions-jsx-content-file-path-src-data-general-rental-conditions-en-mdx" */),
  "component---src-templates-general-rental-conditions-jsx-content-file-path-src-data-general-rental-conditions-fr-mdx": () => import("./../../../src/templates/general-rental-conditions.jsx?__contentFilePath=/home/runner/work/summit-property/summit-property/src/data/general-rental-conditions/fr.mdx" /* webpackChunkName: "component---src-templates-general-rental-conditions-jsx-content-file-path-src-data-general-rental-conditions-fr-mdx" */),
  "component---src-templates-legal-notices-jsx-content-file-path-src-data-legal-notices-en-mdx": () => import("./../../../src/templates/legal-notices.jsx?__contentFilePath=/home/runner/work/summit-property/summit-property/src/data/legal-notices/en.mdx" /* webpackChunkName: "component---src-templates-legal-notices-jsx-content-file-path-src-data-legal-notices-en-mdx" */),
  "component---src-templates-legal-notices-jsx-content-file-path-src-data-legal-notices-fr-mdx": () => import("./../../../src/templates/legal-notices.jsx?__contentFilePath=/home/runner/work/summit-property/summit-property/src/data/legal-notices/fr.mdx" /* webpackChunkName: "component---src-templates-legal-notices-jsx-content-file-path-src-data-legal-notices-fr-mdx" */)
}

